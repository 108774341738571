import { useEffect } from 'react'
import { isDevelopment } from '@/utils'
import { Code, Heading, Text, VStack, Button } from 'ui-components'
import useAuth from '@/hooks/useAuth'
import { captureRelayError } from '@/sentry'

const QueryError = ({ error }: { error: any }) => {
  const { logout } = useAuth()

  useEffect(() => {
    captureRelayError(error)
  }, [error])

  if (isDevelopment) {
    return (
      <VStack spacing={8} p={8}>
        <Heading mt={8}>Ops that query failed </Heading>
        <Code colorScheme="red">{JSON.stringify(error?.message, null, 2)}</Code>
        <Button onClick={logout}>Logout</Button>
      </VStack>
    )
  } else {
    return (
      <>
        <Heading>Something went wrong</Heading>
        <Text>Please refresh the page or contact support if issue continues</Text>
      </>
    )
  }
}
export default QueryError
