import routes from '@/routes'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { graphql, useMutation } from 'relay-hooks'

const useAuthSignOutMutation = graphql`
  mutation useAuthSignOutMutation {
    signOut {
      __typename
      ... on SignOutErrorPayload {
        error {
          message
        }
      }
      ... on SignOutSuccessPayload {
        success
      }
    }
  }
`

const useAuth = () => {
  const router = useRouter()

  const [mutate] = useMutation(useAuthSignOutMutation)

  // We need to store this in a ref so we don't infinite loop when
  // calling mutate causes a rerender
  const logoutRef = useRef<{ loggingOut: boolean; logout: () => void }>()

  if (!logoutRef.current) {
    logoutRef.current = {
      loggingOut: false,
      logout: () => {
        if (logoutRef.current && !logoutRef.current.loggingOut) {
          logoutRef.current.loggingOut = true
          mutate({ variables: {} })
          router.push(routes.login)
        }
      },
    }
  }

  return {
    logout: () => logoutRef.current?.logout(), // Will never be undefined
  }
}

export default useAuth
