const { NODE_ENV } = process.env

export const isBrowser = !!process.browser

const debug = isBrowser && localStorage.getItem('debug') === 'true'

export const isDevelopment: boolean = NODE_ENV === 'development'

export const isDebug = process.env.NODE_ENV === 'development' || debug ? true : false

export const delay = async (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Highest integer we can pass to graphql is 2147483647 cents
export const MAX_INTEGER = 2147483647
export const MAX_CURRENCY = '$21,474,836.47'
