/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useAuthSignOutMutationVariables = {};
export type useAuthSignOutMutationResponse = {
    readonly signOut: {
        readonly __typename: "SignOutErrorPayload";
        readonly error: {
            readonly message: string;
        };
    } | {
        readonly __typename: "SignOutSuccessPayload";
        readonly success: boolean;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type useAuthSignOutMutation = {
    readonly response: useAuthSignOutMutationResponse;
    readonly variables: useAuthSignOutMutationVariables;
};



/*
mutation useAuthSignOutMutation {
  signOut {
    __typename
    ... on SignOutErrorPayload {
      error {
        message
      }
    }
    ... on SignOutSuccessPayload {
      success
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "signOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SignOutErrorPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "SignOutSuccessPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAuthSignOutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useAuthSignOutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "69d2c11880ad22a20dad9da7404dcef1",
    "id": null,
    "metadata": {},
    "name": "useAuthSignOutMutation",
    "operationKind": "mutation",
    "text": "mutation useAuthSignOutMutation {\n  signOut {\n    __typename\n    ... on SignOutErrorPayload {\n      error {\n        message\n      }\n    }\n    ... on SignOutSuccessPayload {\n      success\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9d4ed2a9bfaf70d0311ecce6ea34839';
export default node;
